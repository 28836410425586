.home_root {    
  height: 100vh;
  display: flex;  
  background-image: url('../../../assets/images/background-home.jpeg');
  background-size: cover; 
  background-position: center;    
  
  main {
    align-items: center;        
    margin-left: 57px;
    h3 {
      font-size: 16pt;
      margin: 0;
      margin-bottom: 1rem;
    }
  }
}

.Button {
  color: white;
  background-color: var(--primary-regular);
  border: none;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.2s linear;
  width: 341px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
}


.leftPainel {    
  flex: 1;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.014), white);  
}

.rightPainel {    
  flex: 1;  
}


.cardButtons {      
  position: relative;
  top: 25%;    
}