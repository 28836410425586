#no-results-message-container {
  padding: 1rem;
  background-color: #eeeeee;
  text-align: center;
  span {
    font-weight: bold;
    opacity: 0.8;
  }
  #loading-message-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
}

#pagination-data-container {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
}
