.title_preview_assessment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem !important;
}

.title_preview_assessment > h3 {
    color: #457d93;
}

.preview_evaluation {
    width: 100%;
    background-color: #dedede;
    padding: 0.8rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    gap: 1rem;
}

.print_assessment {
    width: 21cm;
    /* height: 29.7cm;  = 1122px */
    padding: 0.8rem 0;
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    background-color: #fff !important;
}

.page_header {
    width: 100%;
    padding-top: 0.8rem;
    border-bottom: 2px solid var(--primary-regular);
    background-color: #fff;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.info_page {
    width: 50%;
    background-color: var(--primary-regular);
    padding: 4px 16px;
    font-weight: bold;
    font-size: 12px;
    color: white;
}

.info_notebook {
    background-color: #cbcbcb;
    padding: 4px 16px;
    font-weight: bold;
    font-size: 12px;
    color: #000;
    margin-bottom: 8px;
    margin-right: 10px;
}

.page_before_break {
    break-before: page;
}

img {
    max-width: 100%;
}

@page {
  margin: 1px;
  size: A4 portrait;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        margin: 0;
    }

    .preview_evaluation {
        width: 100%;
        background-color: #fff;
        padding: 0;
    
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .print_assessment {
        padding: 0;
    }

    header,
    .container-lateral,
    .container-menu-header,
    .footer,
    #btnPrint {
        display: none !important;
    }

    ::-webkit-scrollbar {
        width: 0px !important;
    }
}