* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    font-family: "Poppins", sans-serif;

    /* ------ Colors ------ */

    /* Neutral */
    --neutral-7: #0F1113;
    --neutral-6: #1D2432;
    --neutral-5: #677080;
    --neutral-4: #969DA9;
    --neutral-3: #CACDD5;
    --neutral-2: #E7E9EE;
    --neutral-1: #F7F8FA;

    /* Primary */
    --primary-body: #032240;
    --primary-dark: #1A367C;
    --primary-regular: #365BDC;
    --primary-light: #A6C9FF;
    --primary-lighter: #EDF1FF;

    /* Secondary */
    --secondary-dark: #760A51;
    --secondary-regular: #DC189B;
    --secondary-light: #F0A8DE;
    --secondary-lighter: #FEEBF9;

    /* Wheat */
    --wheat-dark: #915208;
    --wheat-regular: #F2890D;
    --wheat-light: #F7C8A1;
    --wheat-lighter: #FEF1E7;

    /* Clay */
    --clay-dark: #992400;
    --clay-regular: #FF6333;
    --clay-light: #FAB49E;
    --clay-lighter: #FEECE7;

    /* Cherry */
    --cherry-dark: #7A1F3A;
    --cherry-regular: #D9265C;
    --cherry-light: #F5A3BC;
    --cherry-lighter: #FBE9EF;

    /* Lavender */
    --lavender-dark: #461F7A;
    --lavender-regular: #7633CC;
    --lavender-light: #C3ADEB;
    --lavender-lighter: #F1EBFA;

    /* Evergreen */
    --evergreen-dark: #074624;
    --evergreen-regular: #0D8536;
    --evergreen-light: #93ECB0;
    --evergreen-lighter: #E9FBEF;

    /* Aqua */
    --aqua-dark: #0C403D;
    --aqua-regular: #198079;
    --aqua-light: #B0E8E4;
    --aqua-lighter: #EAFBFA;

    /* Danger */
    --danger-dark: #821729;
    --danger-regular: #E5193B;
    --danger-light: #F8C3CC;
    --danger-lighter: #FEF6F8;

    /* Success */
    --success-dark: #267326;
    --success-regular: #3FCC33;
    --success-light: #B8EBAD;
    --success-lighter: #EDFAEB;

    /* Warning */
    --warning-dark: #835B0E;
    --warning-regular: #F5A508;
    --warning-light: #FFDFAE;
    --warning-lighter: #FEF9F0;

    /* Info */
    --info-dark: #086891;
    --info-regular: #0DADF2;
    --info-light: #A1E5F7;
    --info-lighter: #ECFAFD;

    /* Gradients */
    --fuchsia-clay: linear-gradient(91.58deg, #FEECE7 0%, #E519A0 100%);
    --lavender-fuchsia: linear-gradient(91.58deg, #F0A8DE 0%, #5E29A3 100%);
    --cobalt-evergreen: linear-gradient(91.58deg, rgba(158, 196, 250, 0.8) 0%, #0D5BF2 100%);

    /* ------ Font Size & Line Height ------ */
    --font-size-h1: 4.2rem;
    --line-height-h1: 5rem;

    --font-size-h2: 3.6rem;
    --line-height-h2: 4.5rem;

    --font-size-h3: 3rem;
    --line-height-h3: 3.8rem;

    --font-size-h4: 2.8rem;
    --line-height-h4: 3.5rem;

    --font-size-h5: 2.2rem;
    --line-height-h5: 2.7rem;

    --font-size-h6: 1.8rem;
    --line-height-h6: 2.2rem;

    --font-size-body-m: 1.6rem;
    --line-height-body-m: 2.4rem;

    --font-size-body-s: 1.4rem;
    --line-height-body-s: 2.1rem;

    --font-size-body-xs: 1.2rem;
    --line-height-body-xs: 1.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
* {
    font-family: "Poppins", "Roboto", sans-serif !important;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: var(--primary-lighter);
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary-regular);
    transition: all 0.2s linear;
    cursor: grab;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-dark);
}