.cover_evaluation_content {
    padding: 4rem 50pt 0;
}

.cover_evaluation_header {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 0.4rem;
}

.cover_evaluation_header div:first-child {
    border: 2px solid #444;
    padding: 0.4rem;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.cover_evaluation_header div:first-child span:first-child {
    font-weight: bold;
    font-size: 14pt;
    line-height: 21px;
}

.cover_evaluation_header div:first-child span:nth-child(2) {
    font-size: 14pt;
}

.cover_evaluation_header div:first-child span:nth-child(3) {
    margin-top: 1rem;
    font-size: 12pt;
}

.cover_evaluation_header div:last-child {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cover_evaluation_register_student {
    width: 100%;
    border: 2px solid #444;
    padding: 0.4rem;
    margin: 1rem 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
}

.cover_evaluation_register_student > div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    font-size: 10pt;
}

.line {
    flex: 1;
    height: 1px;
    background-color: #202020;
}