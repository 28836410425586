.main {
    display: flex;
    justify-content: center;
}

.main-content {
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50;
}


@media (max-width: 768px) {

    .main-content {
        width: 95%;
        padding-left: 10px;
        padding-right: 10px;
    }
}