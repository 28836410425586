@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100&display=swap");

html,
body,
#root {
  padding: 0px !important;
  margin: 0px !important;
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #01618e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #01618e;
}

.box-container-sub-content {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.container-lateral {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 25%;
}

.sup-fixed-menu {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  flex-wrap: "wrap";
  width: 75%;
  background-color: #5ab2e0;
  border-radius: 10px;
  margin-left: "auto";
  margin-right: "0";
}

.find-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.nav-edit-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.admin-full-screen {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
}

.footer-admin-content {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 75%;
}

.edit-admin-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 75%;
}

.find-bar {
  width: 100%;
  margin-left: 0;
  margin-right: auto;
}

.nav-edit-menu {
  width: 100%;
  margin-left: 0;
  margin-right: auto;
}

.nav-lateral {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
}

.botoes-edicao {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: auto;
}

.botao-admin-header {
  background-color: white;
}

.botao-admin-header:hover {
  background-color: #e6e6ea;
}

.label-tree-nav {
  text-decoration: none;
  color: black;
}

.Mui-selected {
  background: #5ab2e0 !important;
}

.Mui-selected
  > .MuiTreeItem-label
  > .label-tree-nav
  > .content-label-tree-nav
  > .content-label-tree-nav-icon
  > .icon-tree-view-menu
  > svg
  > path {
  fill: white !important;
}

.Mui-selected
  > .MuiTreeItem-label
  > .label-tree-nav
  > .content-label-tree-nav
  > .content-label-tree-nav-text
  > .label {
  color: #ffffff !important;
  font-weight: bold !important;
}

.button-edit-nav-icon-enabled > svg > path {
  fill: white !important;
}

.button-edit-nav-icon-enabled > svg > circle {
  fill: #5ab2e0 !important;
}

.button-edit-nav-icon-disabled > svg > path {
  fill: #8a9da0 !important;
}

.button-edit-nav-icon-disabled > svg > circle {
  fill: #d3dde3 !important;
}
