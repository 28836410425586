.transcription_page {
    padding: 1rem 0.8rem;
}

.transcription_page > .area_title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.transcription_page > .area_title:before {
    content: "";
    flex: 1;
    height: 2px;
    background-color: var(--primary-dark);
    margin-right: 8px;
}

.transcription_page > .area_title:after {
    content: "";
    flex: 1;
    height: 2px;
    background-color: var(--primary-dark);
    margin-left: 8px;
}

.transcription_page > .area_title > .title {
    color: var(--neutral-1);
    background-color: var(--primary-dark);
    border-radius: 8px;
    padding: 0.8rem;
    font-size: 1.6rem;
    font-family: "Poppins", "Roboto", sans-serif;
    font-weight: 600;
}

.area_question_title {
    font-family: "Poppins", "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.area_question_title > .question_title {
    font-family: "Poppins", "Roboto", sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 4px;
    padding: 0.4rem;
    width: fit-content;
    text-align: center;
}

.fieldset_info {
    border-radius: 4px;
    margin-bottom: 1rem;
    border-color: #fffc;
}

.fieldset_info > legend {
    font-family: "Poppins", "Roboto", sans-serif;
    padding: 0 0.4rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: #202020;
}

.fieldset_info.border_none {
    border: none;
}

.btns_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}

.navigate_transcriptions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px dashed #cbcbcb;
}