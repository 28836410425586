.content_item_evaluation {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.8rem;

    padding: 15pt 50pt;
}

.content_item_evaluation > .num_item {
    border: 2px solid var(--primary-regular);
    padding: 0.4rem;
    text-align: center;
    font-family: 'Quicksand' !important;
    font-weight: bold;
}

.content_item_evaluation > .area_qr_code {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content_item_evaluation > .alternatives {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.4rem;
}

.content_item_evaluation > .alternatives > .alternative {
    display: flex;
    align-items: flex-start;
    gap: 0.6rem;
}

.content_item_evaluation > .alternatives > .alternative > .letter_question {
    min-width: 1.4rem;
}

.content_item_evaluation > .lines_answer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2rem;
    
    padding: 1.4rem 0.8rem 0.4rem;

    border: 4px solid #242424;
}

.content_item_evaluation > .lines_answer > hr {
    background-color: #000 !important;
    opacity: 1;
    width: 100%;
}