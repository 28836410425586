.table_custom {
    margin: 2rem 0;

    width: 100%;
    border-collapse: collapse;

    border-radius: .6rem .6rem 0 0;
    overflow: hidden;
}

.table_custom > thead {
    background-color: var(--gray-2);
    margin: 0;
}

.thead_custom_th {
    padding: 1.3rem .8rem;

    text-align: left;
    font-family: 'Poppins';
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
    color: var(--neutral-7);

    display: flex;
    align-items: center;
    gap: .4rem;
}

.thead_custom_th > button > svg {
    font-size: 2rem !important;
}

.table_custom > tbody > tr:nth-child(even) {
    background-color: var(--gray-1) !important;
}

.tbody_custom_td {
    padding: 1rem .8rem;

    text-align: left;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    line-height: 2.1rem;
    color: var(--neutral-7);

    display: flex;
    align-items: stretch;
    gap: .4rem;
}

.pagination_custom {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination_custom > span {
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 1.4rem;
    color: var(--neutral-7);
}