.searchbar_container {
    gap: 3rem;
    padding: 2rem 1rem 2rem;
    /* height: 20px; */
}

.button {
    width: Hug (102px)px;
    height: Fill (41px)px;
    padding: 8px 16px 8px 16px;
    gap: 12px;
    border-radius: 4px 0px 0px 0px;
    opacity: 0px;

}

.button.secondary {
    background-color: var(--info-light);
    color: var(--info-dark);
    width: Hug (102px)px;
    height: Fill (41px)px;
    padding: 8px 16px 8px 16px;
    gap: 12px;
    border-radius: 4px 0px 0px 0px;
    opacity: 0px;

}

.button:hover {
    filter: brightness(0.9);
}

.textfield {
    font-family: "Poppins";
    background-color: #FFF;    
}

.divSearchCss {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.divSearchCss>div {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 840px) {
    .divSearchCss {
        flex-direction: column;
    }
}

.divActionButtonsCss {
    float: right;
}

.titleInfo {
    text-align: center !important;    
    font-weight: 600 !important;
    font-size: 1.6rem !important;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;    
    margin-bottom: 20px !important;
}

.titleInfo>span {
    font-size: 1rem;
    color: var(--neutral-4);
}