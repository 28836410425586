html{
	font-size: 10pt;
}

body {
	display: relative;
	margin: 0;
	width: 100%;
	height: 100vh;
	font-family: 'Poppins';
}

code {
	font-family: 'Poppins';
}

@font-face {
	font-family: 'Quicksand';
	src: url(./assets/fonts/Quicksand.ttf);
}
