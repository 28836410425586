.content_loading {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;

    background-color: #1d1d1d6b;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem 0.8rem;
    z-index: 9999;
}

.area_loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    
    padding: 0.8rem;
    background-color: #ededd1;
    border-radius: 4px;
}

.text_loading {
    font-weight: bold;
    text-align: center !important;
}