.menu__icon_apps {
    color: var(--neutral-1);
    font-size: 32px !important;
}

.menu__content {
    padding: 10px;
}

.menu__title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--neutral-2);
    font-size: 16px;
    font-weight: normal;
}

.menu__items_navigate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
}

.menu_item_button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content !important;
}

.menu_item_button__title {
    color: var(--neutral-7);
    font-weight: 500;
    font-size: 12px;
    text-transform: none;
}